import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/allergy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/allergy/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/allergy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_Allergy.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/allergy_960x405_new.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> ALLERGY
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Sun sensitive skin protection</h3>
                      <p>
                        With sun sensitive skin, even minimal exposure to UV
                        rays can cause prickly sensations and blotchiness making
                        it difficult to enjoy the sun. PIZ BUIN<sup>®</sup>{" "}
                        ALLERGY is specially developed with dermatologists to
                        protect sun sensitive skin. It combines immediate and
                        effective UVA/UVB sun protection with CALMANELLE
                        <sup>®</sup>, a unique anti irritant shield complex*
                        proven to help strengthen the skin‘s resilience to the
                        sun. CALMANELLE<sup>®</sup> is formulated with the
                        powerful anti-oxidant FEVERFEW PFE<sup>™</sup> to help
                        increase the skin cells tolerance* to the sun. <br />
                        PIZ BUIN<sup>®</sup> ALLERGY, embrace the sun without
                        the worry.
                      </p>
                      <p>
                        <span className="footNote">*In vitro test</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="sun-sensitive-skin-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/297c5be735de2caeff5967744967016d_f22.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SUN SENSITIVE SKIN LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The lotion is especially developed for sun sensitive
                          skin, it absorbs quickly and provides hours of
                          moisturisation to soothe sun sensitive skin.
                          <br />
                          PIZ BUIN<sup>®</sup> ALLERGY Sun Sensitive Skin Lotion
                          is sweat and water resistant
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <h2>Available in sizes</h2>
                        <p>
                          <em />
                          200 ml and 400 ml (ALLERGY Lotion SPF 30)
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    The Feverfew plants origin is in the
                                    mountain scrub and rocky soil of the Balkan
                                    Peninsula but now it can be found in several
                                    areas of the world. The PIZ BUIN<sup>®</sup>{" "}
                                    parent company, Kenvue, has
                                    patented this innovative extract for use in
                                    skincare, applying a new extraction process
                                    that keeps the active ingredients and
                                    eliminates unwanted components that could
                                    cause allergies.
                                  </p>
                                  <p>
                                    How does FEVERFEW PFE<sup>™</sup> help your
                                    skin?
                                  </p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> plant extract is a
                                    highly effective soothing and antioxidant
                                    ingredient. In vitro studies prove that it
                                    has greater antioxidant boosting activity
                                    than other leading extracts including sage,
                                    green tea, black tea, Vitamin C and Vitamin
                                    E. It helps relieve redness of skin, repairs
                                    skin cell damaged by UV rays, and
                                    strengthens skin cell resilience to the
                                    sun*. Its efficacy has been proven by
                                    several years of extensive research,
                                    performed by the PIZ BUIN<sup>®</sup>{" "}
                                    brand’s parent company, Kenvue, with several patents covering its
                                    application in the skin care and cosmetic
                                    fields.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      Source: Parthenolide-depleted Feverfew
                                      (Tanacetum parthenium) protects skin from
                                      UV irradiation and external aggression,
                                      Arch Dermatol Res (2008) 300:69–80
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 1<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against cell alterations, decreases
                                    UVB-induced erythema and helps repair
                                    UV-damaged cells. Up to 60% improvement on
                                    UVB induced erythema and redness.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue publication
                                      in Arch Dermatol Res Feb 2008; 300 (2)
                                      69-80, Randomised, placebo-controlled,
                                      double blind study on 12 volunteers.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against sunburn by stimulating natural
                                    processes that help maintain skin cell
                                    integrity* and strengthen their resilience
                                    to the sun.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue R&amp;D,
                                      Method: application of UV radiations on
                                      human skin ex plant and assessing the
                                      sunburn cells induced.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, a unique shield
                                    complex proven to help strenghten the skin's
                                    resilience to the sun*. Calmanelle
                                    <sup>®</sup> combines the powerful
                                    anti-oxidant FEVERFEW PFE<sup>™</sup>, to
                                    help increase the skin cells tolerance* to
                                    the sun, with a gentle soothing agent
                                    specially developed for sensitive and itchy
                                    skin.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="sun-sensitive-skin-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/3a488267f1eccefa2b7a04c9d52a3214_f23.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SUN SENSITIVE SKIN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The spray is especially developed for sun sensitive
                          skin, it absorbs quickly and provides hours of
                          moisturisation to soothe sun sensitive skin. The
                          practical spray allows an easy and even application.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight. Do not spray directly into the face. For use
                          on face, spray into hands and apply.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    The Feverfew plants origin is in the
                                    mountain scrub and rocky soil of the Balkan
                                    Peninsula but now it can be found in several
                                    areas of the world. The PIZ BUIN<sup>®</sup>{" "}
                                    parent company, Kenvue, has
                                    patented this innovative extract for use in
                                    skincare, applying a new extraction process
                                    that keeps the active ingredients and
                                    eliminates unwanted components that could
                                    cause allergies.
                                  </p>
                                  <p>
                                    How does FEVERFEW PFE<sup>™</sup> help your
                                    skin?
                                  </p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> plant extract is a
                                    highly effective soothing and antioxidant
                                    ingredient. In vitro studies prove that it
                                    has greater antioxidant boosting activity
                                    than other leading extracts including sage,
                                    green tea, black tea, Vitamin C and Vitamin
                                    E. It helps relieve redness of skin, repairs
                                    skin cell damaged by UV rays, and
                                    strengthens skin cell resilience to the
                                    sun*. Its efficacy has been proven by
                                    several years of extensive research,
                                    performed by the PIZ BUIN<sup>®</sup>{" "}
                                    brand’s parent company, Kenvue, with several patents covering its
                                    application in the skin care and cosmetic
                                    fields.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      Source: Parthenolide-depleted Feverfew
                                      (Tanacetum parthenium) protects skin from
                                      UV irradiation and external aggression,
                                      Arch Dermatol Res (2008) 300:69–80
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 1<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against cell alterations, decreases
                                    UVB-induced erythema and helps repair
                                    UV-damaged cells. Up to 60% improvement on
                                    UVB induced erythema and redness.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue publication
                                      in Arch Dermatol Res Feb 2008; 300 (2)
                                      69-80, Randomised, placebo-controlled,
                                      double blind study on 12 volunteers.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against sunburn by stimulating natural
                                    processes that help maintain skin cell
                                    integrity* and strengthen their resilience
                                    to the sun.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue R&amp;D,
                                      Method: application of UV radiations on
                                      human skin ex plant and assessing the
                                      sunburn cells induced.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, a unique shield
                                    complex proven to help strenghten the skin's
                                    resilience to the sun*. Calmanelle
                                    <sup>®</sup> combines the powerful
                                    anti-oxidant FEVERFEW PFE<sup>™</sup>, to
                                    help increase the skin cells tolerance* to
                                    the sun, with a gentle soothing agent
                                    specially developed for sensitive and itchy
                                    skin.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="sun-sensitive-skin-face-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/e0d66d56925b5108b4a15844b378f0f1_f21.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SUN SENSITIVE SKIN FACE CREAM</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          Your face is the part of your body that gets the most
                          sun exposure during the day. Especially sun sensitive
                          skin needs special protection. The cream is especially
                          developed for sun sensitive face skin. It absorbs
                          quickly and provides hours of moisturisation to soothe
                          the delicate skin of the face and keep it silky soft.
                          <br />
                          PIZ BUIN<sup>®</sup> ALLERGY Sun Sensitive Skin Face
                          Cream is sweat and water resistant.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    The Feverfew plants origin is in the
                                    mountain scrub and rocky soil of the Balkan
                                    Peninsula but now it can be found in several
                                    areas of the world. The PIZ BUIN<sup>®</sup>{" "}
                                    parent company, Kenvue, has
                                    patented this innovative extract for use in
                                    skincare, applying a new extraction process
                                    that keeps the active ingredients and
                                    eliminates unwanted components that could
                                    cause allergies.
                                  </p>
                                  <p>
                                    How does FEVERFEW PFE<sup>™</sup> help your
                                    skin?
                                  </p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> plant extract is a
                                    highly effective soothing and antioxidant
                                    ingredient. In vitro studies prove that it
                                    has greater antioxidant boosting activity
                                    than other leading extracts including sage,
                                    green tea, black tea, Vitamin C and Vitamin
                                    E. It helps relieve redness of skin, repairs
                                    skin cell damaged by UV rays, and
                                    strengthens skin cell resilience to the
                                    sun*. Its efficacy has been proven by
                                    several years of extensive research,
                                    performed by the PIZ BUIN<sup>®</sup>{" "}
                                    brand’s parent company, Kenvue, with several patents covering its
                                    application in the skin care and cosmetic
                                    fields.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      Source: Parthenolide-depleted Feverfew
                                      (Tanacetum parthenium) protects skin from
                                      UV irradiation and external aggression,
                                      Arch Dermatol Res (2008) 300:69–80
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 1<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against cell alterations, decreases
                                    UVB-induced erythema and helps repair
                                    UV-damaged cells. Up to 60% improvement on
                                    UVB induced erythema and redness.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue publication
                                      in Arch Dermatol Res Feb 2008; 300 (2)
                                      69-80, Randomised, placebo-controlled,
                                      double blind study on 12 volunteers.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against sunburn by stimulating natural
                                    processes that help maintain skin cell
                                    integrity* and strengthen their resilience
                                    to the sun.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue R&amp;D,
                                      Method: application of UV radiations on
                                      human skin ex plant and assessing the
                                      sunburn cells induced.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, a unique shield
                                    complex proven to help strenghten the skin's
                                    resilience to the sun*. Calmanelle
                                    <sup>®</sup> combines the powerful
                                    anti-oxidant FEVERFEW PFE<sup>™</sup>, to
                                    help increase the skin cells tolerance* to
                                    the sun, with a gentle soothing agent
                                    specially developed for sensitive and itchy
                                    skin.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/allergy/"
                      target="_self"
                    >
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
